<div class="container footer text-muted">
    <div class="row">
        <div class="col">
            &copy; {{year}}, innoVortex.
        </div>
        <div class="col text-center"></div>
        <div class="col text-right">
            {{'versionNumber' | i18n : version}}
        </div>
    </div>
</div>
